<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <modal-status :status-data.sync="statusData" :status-options="taskStageOptions" :trigger="trigger"
          @refetch-data="refetchData" />
        <modal-log :log-data="logData" />
        <modal-details-remark :report-data.sync="reportData" @refetch-data="refetchData" />

        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">{{ page }}</h3>
          <div class="form-col-select ml-2">
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input v-model="searchQuery" class="d-inline-block" placeholder="Find User" />
          </div>
        </div>
        <div class="d-flex flex-wrap flex-sm-nowrap justify-content-sm-start mt-1 mt-lg-0">
          <b-button class="mr-sm-2 mobile-w100" variant="primary" @click="stageFilter = null">
            <feather-icon icon="AlignJustifyIcon" class="mr-50" />
            All
          </b-button>
          <b-button class="mr-sm-2 mt-1 mt-sm-0 mobile-w100" variant="primary" @click="stageFilter = '1'">
            <feather-icon icon="BookOpenIcon" class="mr-50" />
            Open
          </b-button>
          <b-button class="mr-sm-2 mt-1 mt-sm-0 mobile-w100" variant="primary" @click="stageFilter = '2'">
            <feather-icon icon="LoaderIcon" class="mr-50" />
            In Progress
          </b-button>
          <b-button class="mt-1 mt-sm-0 mobile-w100" variant="primary" @click="stageFilter = '3'">
            <feather-icon icon="CheckIcon" class="mr-50" />
            Completed
          </b-button>
        </div>
      </div>

      <b-modal id="modal-center" centered title="Vertically Centered" ok-only ok-title="Accept">
        <b-card-text>
          Croissant jelly-o halvah chocolate sesame snaps. Brownie caramels candy canes
          chocolate cake marshmallow icing lollipop I love. Gummies macaroon donut
          caramels biscuit topping danish.
        </b-card-text>
        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="taskStageOptions" label="stage_name"
          :reduce="(option) => option.id" :clearable="false" placeholder="Stage" />
      </b-modal>

      <b-table ref="refStaffWelcomeTable" class="position-relative" :items="fetchStaffWelcomeTask" responsive
        :fields="categoryFilter == 11 ? tableColumnsPassport : tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <template #cell(records)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-if="data.item.records.length > 0" @click="openLogModal(data.item.records)">
            <feather-icon icon="AlertCircleIcon" />
          </b-button>
        </template>

        <template #head(passport_expiry)="data">
          <template v-if="categoryFilter == 11">User's Passport Expiry Date</template>
          <template v-else></template>
        </template>

        <template #cell(created_at)="data">
          <template>{{ data.item.created_at.split("T")[0] }}</template>
        </template>

        <template #cell(task_stage_id)="data">
          <a href="javascript:void(0)" @click="handleChangeStatus(data.item)"
            class="d-flex align-items-center justify-content-start">
            <b-badge :style="{
              background: data.item.task_stage.background_color,
              color: data.item.task_stage.font_color,
            }" size="18" class="badge_item mr-50">
              <template v-if="data.item.task_stage_id == 1"> Open </template>
              <template v-if="data.item.task_stage_id == 2"> In Progress </template>
              <template v-if="data.item.task_stage_id == 3"> Complete </template>
            </b-badge>
          </a>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item v-if="ability.can('read', 'actionlist')"
              :to="{ name: 'todo-staff-detail', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('update', 'actionlist')" @click="openModalDetailsRemark(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update Remarks</span>
            </b-dropdown-item>

            <!--
              <b-dropdown-item v-if="ability.can('delete','users')">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
              -->
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalStaffWelcomeTask" :per-page="perPage" first-number
              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  VBToggle,
  VBModal,
  BOverlay,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import useStaffWelcomeList from "./useStaffWelcomeList";
import staffWelcomeStoreModule from "../staffWelcomeStoreModule";
import ModalStatus from "../../modal/ModalStatus.vue";
import ModalLog from "../../modal/ModalLog.vue";
import ModalDetailsRemark from "../../modal/ModalDetailsRemark.vue";
// import UserListAddNew from "./UserListAddNew.vue";
// import UserPasswordEdit from "./UserPasswordEdit.vue";
import ability from "@/libs/acl/ability";

export default {
  components: {
    // UserListAddNew,
    // UserPasswordEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,
    VBToggle,
    VBModal,
    BOverlay,
    BForm,
    BFormGroup,
    vSelect,
    ModalStatus,
    ModalLog,
    ModalDetailsRemark,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  methods: {},
  data() {
    return {
      page: "",
      statusData: {},
      reportData: {},
      logData: {},
      trigger: false,
      editDetails: "",
    };
  },
  methods: {
    checkRoute(router_name) {
      if (router_name == "welcome-staff") {
        this.categoryFilter = 1;
        this.page = "Welcome Letter";
      } else if (router_name == "course-compliance") {
        this.categoryFilter = 6;
        this.page = "CPT/CPD Compliance";
      } else if (router_name == "annual-return") {
        this.categoryFilter = 7;
        this.page = "SFC Annual Return";
      } else if (router_name == "annual-compliance") {
        this.categoryFilter = 8;
        this.page = "Annual Compliance";
      } else if (router_name == "user-passport-expiry") {
        this.categoryFilter = 11;
        this.page = "User Passport Expiry";
      }
    },
    handleEdit(id) {
      console.log(id);
      this.editDetails = id;
    },
    onSubmit(obj) {
      console.log(obj);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let data = {
            id: obj.id,
            task_stage_id: obj.task_stage_id,
          };
          console.log(data);
          this.loading = false;
          store
            .dispatch("app-user/updateTask", data)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleChangeStatus(item) {
      if (ability.can("update", "actionlist")) {
        this.statusData = { ...item };
        this.trigger = !this.trigger;
        this.$bvModal.show("modal-status");
      } else {
        this.$swal({
          text: "You don't have permission to update!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
      }
    },
    openModalDetailsRemark(item) {
      this.reportData = { ...item };
      this.$bvModal.show("modal-details-remark");
    },
    openLogModal(item) {
      this.logData = { ...item };
      this.$bvModal.show("modal-logs");
    },
  },
  watch: {
    $route(to, from) {
      console.log("route", to, from);
      this.checkRoute(to.name);
    },
  },
  mounted() {
    let router_name = this.$router.currentRoute.name;
    this.checkRoute(router_name);
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    const taskStageOptions = ref([]);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptionList", {
          task_stage: true,
        })
        .then((response) => {
          taskStageOptions.value = response.data.task_stages;
        });
    };

    onMounted(() => {
      fetchOptions();
    });

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, staffWelcomeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      fetchStaffWelcomeTask,
      tableColumns,
      tableColumnsPassport,
      perPage,
      currentPage,
      totalStaffWelcomeTask,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStaffWelcomeTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      stageFilter,
      categoryFilter,
    } = useStaffWelcomeList();
    console.log(refStaffWelcomeTable);

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchStaffWelcomeTask,
      tableColumns,
      tableColumnsPassport,
      perPage,
      currentPage,
      totalStaffWelcomeTask,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStaffWelcomeTable,
      refetchData,
      ability,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      stageFilter,
      categoryFilter,
      fetchOptions,
      taskStageOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.badge_item {
  min-width: 100px;
  padding: 0.5rem;
}
</style>
