import axios from "@axios";
import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWelcomeTaskStaff(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/task/staff/list/", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // {id: 1, task_stage_id:1}
    updateStage(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/task/stage", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTask(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/task", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/task/record", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteTask(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/task/record", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStaffTaskAttachment(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/task/getStaffFile/", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStaffTask(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/task/staff/", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    sendEmail(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/email/resendEmail", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
