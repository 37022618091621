import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refStaffWelcomeTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "task id", sortable: true },
    { key: "user_name", label: "User Name", sortable: true },
    { key: "user_email", label: "Email", sortable: true },
    { key: "created_at", label: "Issue Date", sortable: true },
    { key: "company_name", label: "Company Name", sortable: true },
    { key: "task_stage_id", label: "Status", sortable: true },
    { key: 'records', label: 'Remarks'},
    // { key: "created_at", label: "created at", sortable: true },
    { key: "actions" },
  ];

  // Table Handlers
  const tableColumnsPassport = [
    { key: "id", label: "task id", sortable: true },
    { key: "user_name", label: "User Name", sortable: true },
    { key: "user_email", label: "Email", sortable: true },
    { key: "created_at", label: "Issue Date", sortable: true },
    { key: "company_name", label: "Company Name", sortable: true },
    { key: "passport_expiry", sortable: true },
    { key: "task_stage_id", label: "Status", sortable: true },
    { key: 'records', label: 'Remarks'},
    // { key: "created_at", label: "created at", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalStaffWelcomeTask = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const stageFilter = ref(null);
  const categoryFilter = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refStaffWelcomeTable.value
      ? refStaffWelcomeTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStaffWelcomeTask.value,
    };
  });

  const refetchData = () => {
    refStaffWelcomeTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      stageFilter,
      categoryFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchStaffWelcomeTask = (ctx, callback) => {
    store
      .dispatch("app-user/fetchWelcomeTaskStaff", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
        stage_id: stageFilter.value,
        task_category_id: categoryFilter.value,
      })
      .then((response) => {
        console.log(response);
        const { tasks, total } = response.data;
        callback(tasks);
        totalStaffWelcomeTask.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === "active") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  return {
    fetchStaffWelcomeTask,
    tableColumns,
    tableColumnsPassport,
    perPage,
    currentPage,
    totalStaffWelcomeTask,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStaffWelcomeTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    stageFilter,
    categoryFilter,
  };
}
